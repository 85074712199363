
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPencil, mdiNotebookEditOutline, mdiArrowLeftThin } from '@mdi/js'

import Assignments from '@/views/contractData/views/assignments/Assignments.vue'

import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { handleError } from '@/utils/handleError'
import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { dateDashNotationToDot } from '@/utils/convertDate'

import { useGetPerson } from '@/api/person'

import { Rights } from '@/api/types/right'
import { ContractConsideration } from '@/api/types/person'

export default defineComponent({
  name: 'UpperPersonProfile',
  components: {
    Assignments,
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    AddEditPersonDialog: () => import('@/views/persons/views/components/AddEditPersonDialog.vue'),
  },
  props: {
    personId: {
      type: Number,
      required: true,
    },
    personNeedReload: {
      type: Boolean,
      default: false,
    },
    assignmentsNeedReload: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root, emit }) => {
    const { getPerson, data: person, isLoading: isLoadingPerson } = useGetPerson()

    try {
      getPerson(Number(props.personId))
    } catch (error: unknown) {
      handleError(error)
    }

    const ASSIGNMENTS_TABLE_HEADERS = [
      {
        text: root.$t('persons.profile.tabMenu.assignments.positionId'),
        value: 'positionId',
        sortable: false,
        width: '120px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
        width: '300px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.secondLevelProfessionalUnit'),
        value: 'secondLevelProfessionalUnit.name',
        sortable: false,
        width: '300px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.professionalUnit'),
        value: 'professionalUnit.name',
        sortable: false,
        width: '300px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.profile'),
        value: 'profile.name',
        sortable: false,
        width: '200px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.scope'),
        value: 'scope',
        sortable: false,
        width: '100px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.start'),
        value: 'start',
        sortable: true,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.end'),
        value: 'end',
        sortable: true,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const ADDITIONAL_ASSIGNMENT_FILTERS_UPPER = {
      personIds: Number(root.$route.params.id),
      startTo: new Date().toISOString().split('T')[0],
      endFrom: new Date().toISOString().split('T')[0],
    }

    const isAddEditPersonModalOpen = ref(false)

    const isNotesDialogOpen = ref(false)

    const personNotes = computed(() => person.value?.notes ?? [])

    async function onReloadNotes(): Promise<void> {
      const { getPerson, data: personLocal } = useGetPerson()

      await getPerson(Number(props.personId))

      person.value = personLocal.value
    }

    function onClickBack(): void {
      root.$router.back()
    }

    function onReloadLowerData(): void {
      emit('reload-lower-data')
    }

    function onReloadedUpperData(): void {
      emit('reloaded-upper-data')
    }

    watch(
      () => props.personNeedReload,
      () => {
        if (props.personNeedReload) {
          getPerson(Number(props.personId))
          onReloadedUpperData()
        }
      },
      { immediate: true }
    )

    function convertEndOfCurrentContract(contractConsiderations: ContractConsideration[]): string {
      const contractEnds = contractConsiderations.map((contract) =>
        dateDashNotationToDot(contract.endOfCurrentContract)
      )

      return contractEnds.join(', ')
    }

    function convertMonthUntilEndOfCurrentContract(contractConsiderations: ContractConsideration[]): string {
      const contractEnds = contractConsiderations
        .map((contract) => contract.monthUntilEndOfCurrentContract)
        .filter((month) => month)

      if (!contractEnds.length) return '-'

      return contractEnds.join(', ')
    }

    const isInUhd36 = computed<boolean>(() => {
      if (!person.value) return false

      const today = new Date()

      const uhd36EndDate = new Date(person.value.anuePeriod36EndDate)

      return today < uhd36EndDate
    })

    return reactive({
      icons: {
        mdiPencil,
        mdiNotebookEditOutline,
        mdiArrowLeftThin,
      },
      constants: {
        Rights,

        ASSIGNMENTS_TABLE_HEADERS,
        ADDITIONAL_ASSIGNMENT_FILTERS_UPPER,
      },
      state: {
        person,
        isLoadingPerson,

        isAddEditPersonModalOpen,

        isNotesDialogOpen,
        personNotes,

        isInUhd36,
      },
      listeners: {
        onReloadNotes,

        onReloadLowerData,
        onReloadedUpperData,

        onClickBack,
      },
      functions: {
        getPerson,

        convertDotIntoCommaInput,

        hasSufficientRights,

        dateDashNotationToDot,

        convertEndOfCurrentContract,
        convertMonthUntilEndOfCurrentContract,
      },
    })
  },
})
