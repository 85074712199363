var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    staticClass: "mb-3 rounded-lg white",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onClickBack();
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiArrowLeftThin) + " ")]), _vm._v(" " + _vm._s(_vm.$t('persons.profile.action.back')) + " ")], 1), _vm.state.isLoadingPerson ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('persons.profile.loadingPerson')) + " ")]) : _vm.state.person ? _c('v-card', {
    staticClass: "rounded-lg elevation-0"
  }, [_c('v-card-title', {
    staticClass: "d-flex"
  }, [_c('div', [_c('h2', {
    staticClass: "text-h5 font-weight-bold mr-3"
  }, [_vm._v(" " + _vm._s(_vm.state.person.firstName) + " " + _vm._s(_vm.state.person.lastName) + " ")]), _c('h3', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.id')) + ": "), _c('span', {
    staticClass: "text-subtitle-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.state.person.id) + " ")])])]), _vm.state.person.activeContractualRelationships.length ? _c('v-chip-group', {
    staticClass: "align-self-start mt-1"
  }, [_vm.state.person.activeContractualRelationships.length ? _c('div', _vm._l(_vm.state.person.activeContractualRelationships, function (contractRelationship) {
    return _c('v-chip', {
      key: contractRelationship,
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(contractRelationship) + " ")]);
  }), 1) : _vm._e(), _vm.state.person.specialRoles.length ? _c('div', _vm._l(_vm.state.person.specialRoles, function (specialRole) {
    return _c('v-chip', {
      key: specialRole.id,
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(specialRole.name) + " ")]);
  }), 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column align-end ml-auto"
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.PERSON_UPDATE) ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isAddEditPersonModalOpen = true;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e(), _vm.state.isAddEditPersonModalOpen ? _c('AddEditPersonDialog', {
    attrs: {
      "person-to-edit": _vm.state.person,
      "is-edit-mode": true
    },
    on: {
      "added-edited": function addedEdited($event) {
        _vm.functions.getPerson(Number(_vm.personId));
      },
      "close": function close($event) {
        _vm.state.isAddEditPersonModalOpen = false;
      }
    },
    model: {
      value: _vm.state.isAddEditPersonModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditPersonModalOpen", $$v);
      },
      expression: "state.isAddEditPersonModalOpen"
    }
  }) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_OWN) ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "text-subtitle-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.state.person.notesCount) + " ")]), _c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isNotesDialogOpen = true;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiNotebookEditOutline) + " ")])], 1)], 1) : _vm._e(), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.personNotes,
      "entity-name": "person",
      "entity-id": _vm.state.person.id
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e()], 1)], 1), _c('v-card-text', {
    staticClass: "card-text__wrapper"
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.ASSIGNMENT_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.ASSIGNMENT_READ_OWN) ? _c('Assignments', {
    attrs: {
      "component-table-headers": _vm.constants.ASSIGNMENTS_TABLE_HEADERS,
      "additional-filters": _vm.constants.ADDITIONAL_ASSIGNMENT_FILTERS_UPPER,
      "without-filter-persistence": true,
      "elevation": 0,
      "hide-default-footer": "",
      "assignments-need-reload": _vm.assignmentsNeedReload,
      "is-common-view": false
    },
    on: {
      "reload-profile-data": function reloadProfileData($event) {
        return _vm.listeners.onReloadLowerData();
      },
      "reloaded-assignments": function reloadedAssignments($event) {
        return _vm.listeners.onReloadedUpperData();
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('div')];
      },
      proxy: true
    }, {
      key: "orgUnit",
      fn: function fn() {
        return [_c('div', {
          staticClass: "relative"
        }, [_c('div', {
          staticClass: "absolute black--text d-flex flex-column text-subtitle-2"
        }, [_vm.state.person.supplier ? _c('div', {
          staticClass: "text-spacing"
        }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.supplier')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.state.person.supplier.name) + " ")])]) : _vm._e(), _vm.state.person.organizationalUnit ? _c('div', {
          staticClass: "text-spacing"
        }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.organizationalUnit')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.state.person.organizationalUnit.name) + " ")])]) : _vm._e()])])];
      },
      proxy: true
    }, {
      key: "profUnit",
      fn: function fn() {
        return [_c('div', {
          staticClass: "relative"
        }, [_c('div', {
          staticClass: "absolute black--text d-flex flex-column text-subtitle-2"
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('persons.profile.endOfContract')) + ": "), _vm.state.person.contractConsiderations.length ? _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.functions.convertEndOfCurrentContract(_vm.state.person.contractConsiderations)) + " ")]) : _vm._e()]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('persons.profile.monthsUntilEnd')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.functions.convertMonthUntilEndOfCurrentContract(_vm.state.person.contractConsiderations)) + " ")])]), _vm.state.isInUhd36 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('persons.profile.uhd36')) + ": "), _vm.state.person.contractConsiderations.length ? _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(_vm.state.person.anuePeriod36EndDate)) + " ")]) : _vm._e()]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('persons.profile.uhd54')) + ": "), _vm.state.person.contractConsiderations.length ? _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(_vm.state.person.anuePeriod54EndDate)) + " ")]) : _vm._e()])])])];
      },
      proxy: true
    }, {
      key: "scope",
      fn: function fn() {
        return [_c('div', {
          staticClass: "relative"
        }, [_c('div', {
          staticClass: "absolute black--text d-flex"
        }, [_c('div', {
          staticClass: "mr-4"
        }, [_c('h3', {
          staticClass: "text-subtitle-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.scope')) + " ")]), _c('v-progress-linear', {
          staticClass: "text-subtitle-2 my-1 font-weight-bold",
          attrs: {
            "value": 100 / _vm.state.person.activeContractScope * _vm.state.person.activeAssignmentScope,
            "w": "",
            "color": _vm.state.person.activeAssignmentScope > _vm.state.person.activeContractScope ? 'red lighten-1' : 'grey lighten-1',
            "rounded": "",
            "height": "25"
          }
        }, [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(Number(_vm.state.person.activeAssignmentScope.toFixed(1)))) + " / " + _vm._s(_vm.functions.convertDotIntoCommaInput(Number(_vm.state.person.activeContractScope.toFixed(1)))) + " ")])], 1), _c('div', [_c('h3', {
          staticClass: "text-subtitle-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.targetPerformanceComparison')) + " ")]), _c('v-progress-linear', {
          staticClass: "text-subtitle-2 my-1 font-weight-bold",
          attrs: {
            "value": _vm.state.person.targetPerformanceComparison * 100,
            "color": "grey lighten-1",
            "rounded": "",
            "height": "25"
          }
        }, [_vm._v(" " + _vm._s(Math.round(_vm.state.person.targetPerformanceComparison * 100)) + " % ")])], 1)])])];
      },
      proxy: true
    }], null, false, 2683140387)
  }) : _vm._e()], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }