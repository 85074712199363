var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_c('UpperPersonProfile', {
    attrs: {
      "person-id": _vm.state.currentPersonId,
      "assignments-need-reload": _vm.state.reloadAssignments,
      "person-need-reload": _vm.state.reloadPerson
    },
    on: {
      "reload-lower-data": function reloadLowerData($event) {
        return _vm.listeners.handleDataReload(_vm.constants.RELOAD_TYPE.ASSIGNMENTS);
      },
      "reloaded-upper-data": function reloadedUpperData($event) {
        return _vm.listeners.onReloadedData();
      }
    }
  }), _c('LowerPersonProfile', {
    attrs: {
      "person-id": _vm.state.currentPersonId,
      "need-reload": _vm.state.reloadAssignments
    },
    on: {
      "reload-upper-person": function reloadUpperPerson($event) {
        return _vm.listeners.handleDataReload(_vm.constants.RELOAD_TYPE.PERSON);
      },
      "reload-upper-data": function reloadUpperData($event) {
        return _vm.listeners.handleDataReload();
      },
      "reloaded-lower-data": function reloadedLowerData($event) {
        return _vm.listeners.onReloadedData();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }