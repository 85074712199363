
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'

import Assignments from '@/views/contractData/views/assignments/Assignments.vue'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetPerson } from '@/api/person'

import { BOARDING_TYPE, RELOAD_TYPE } from '@/views/persons/views/personProfile/types'

import { Rights } from '@/api/types/right'

interface Tab {
  title: TranslateResult
  component: (assignments?: unknown) => unknown
  boardingType?: BOARDING_TYPE
  rights: boolean
}

export default defineComponent({
  name: 'LowerPersonProfile',
  components: {
    Assignments,
    AddEditAssignmentDialog: () =>
      import('@/views/contractData/views/assignments/components/AddEditAssignmentDialog.vue'),
  },
  props: {
    personId: {
      type: Number,
      required: true,
    },
    needReload: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root, emit }) => {
    const TABS: Tab[] = [
      {
        title: root.$t('persons.profile.tabMenu.assignments.title'),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        component: (assignments) => ({}),
        rights: hasSufficientRights(Rights.ASSIGNMENT_READ_ALL) || hasSufficientRights(Rights.ASSIGNMENT_READ_OWN),
      },
      {
        title: root.$t('persons.profile.tabMenu.baseData.title'),
        component: () =>
          import('@/views/persons/views/personProfile/components/lowerPersonProfile/components/BaseData.vue'),
        rights: hasSufficientRights(Rights.PERSON_DETAILS_READ),
      },
      {
        title: root.$t('persons.profile.tabMenu.compliance.title'),
        component: () =>
          import(
            '@/views/persons/views/personProfile/components/lowerPersonProfile/components/compliance/Compliance.vue'
          ),
        rights:
          hasSufficientRights(Rights.COMPLIANCE_CHECK_READ_ALL) ||
          hasSufficientRights(Rights.COMPLIANCE_CHECK_READ_OWN),
      },
      {
        title: root.$t('persons.profile.tabMenu.contracts.title'),
        component: () =>
          import('@/views/persons/views/personProfile/components/lowerPersonProfile/components/Contracts.vue'),
        rights: hasSufficientRights(Rights.CONTRACT_READ_ALL) || hasSufficientRights(Rights.CONTRACT_READ_OWN),
      },
      {
        title: root.$t('persons.profile.tabMenu.budget.title'),
        component: () =>
          import('@/views/persons/views/personProfile/components/lowerPersonProfile/components/Budget.vue'),
        rights:
          (hasSufficientRights(Rights.ASSIGNMENT_READ_ALL) || hasSufficientRights(Rights.ASSIGNMENT_READ_OWN)) &&
          (hasSufficientRights(Rights.CONTRACT_READ_ALL) || hasSufficientRights(Rights.CONTRACT_READ_OWN)) &&
          (hasSufficientRights(Rights.WORKING_HOURS_READ_ALL) || hasSufficientRights(Rights.WORKING_HOURS_READ_OWN)),
      },
      {
        title: root.$t('persons.profile.tabMenu.checklists.title'),
        component: () =>
          import('@/views/persons/views/personProfile/components/lowerPersonProfile/components/Checklists.vue'),
        rights: hasSufficientRights(Rights.CHECKLIST_READ_ALL) || hasSufficientRights(Rights.CHECKLIST_READ_OWN),
      },
      {
        title: root.$t('persons.profile.tabMenu.equipment.title'),
        component: () =>
          import(
            '@/views/persons/views/personProfile/components/lowerPersonProfile/components/equipment/Equipment.vue'
          ),
        rights: hasSufficientRights(Rights.EQUIPMENT_READ_ALL) || hasSufficientRights(Rights.EQUIPMENT_READ_OWN),
      },
    ]

    function getAvailableActiveTabCmp(): Tab {
      return TABS.find((tab) => tab.rights) || TABS[0]
    }

    const activeTabComponent = ref(getAvailableActiveTabCmp())

    const { getPerson, data: person, isLoading: isLoadingPerson } = useGetPerson()

    try {
      getPerson(Number(props.personId))
    } catch (error: unknown) {
      handleError(error)
    }

    const ASSIGNMENTS_TABLE_HEADERS = [
      {
        text: root.$t('persons.profile.tabMenu.assignments.positionId'),
        value: 'positionId',
        sortable: false,
        width: '120px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
        width: '300px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.secondLevelProfessionalUnit'),
        value: 'secondLevelProfessionalUnit.name',
        sortable: false,
        width: '300px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.professionalUnit'),
        value: 'professionalUnit.name',
        sortable: false,
        width: '300px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.profile'),
        value: 'profile.name',
        sortable: false,
        width: '200px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.scope'),
        value: 'scope',
        sortable: false,
        width: '100px',
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.start'),
        value: 'start',
        sortable: true,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.end'),
        value: 'end',
        sortable: true,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.assignments.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const ADDITIONAL_ASSIGNMENT_FILTERS_LOWER = {
      personIds: Number(root.$route.params.id),
    }

    function onReloadUpperData(reloadType?: RELOAD_TYPE): void {
      if (reloadType === RELOAD_TYPE.PERSON) {
        emit('reload-upper-person')
      } else {
        emit('reload-upper-data')
      }
    }

    function onReloadedLowerData(): void {
      emit('reloaded-lower-data')
    }

    return reactive({
      constants: {
        Rights,
        RELOAD_TYPE,

        TABS,

        ASSIGNMENTS_TABLE_HEADERS,
        ADDITIONAL_ASSIGNMENT_FILTERS_LOWER,
      },
      state: {
        activeTabComponent,

        person,
        isLoadingPerson,
      },
      listeners: {
        onReloadUpperData,
        onReloadedLowerData,
      },
      functions: {
        hasSufficientRights,
      },
    })
  },
})
