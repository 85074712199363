var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_c('v-tabs', {
    staticClass: "mt-5",
    attrs: {
      "background-color": "background"
    }
  }, _vm._l(_vm.constants.TABS.filter(function (tab) {
    return tab.rights;
  }), function (tab) {
    return _c('v-tab', {
      key: tab.title,
      on: {
        "click": function click($event) {
          _vm.state.activeTabComponent = tab;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.title) + " ")]);
  }), 1), _c('v-card', {
    staticClass: "mt-5 rounded-lg",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', [_vm.state.activeTabComponent.component.length && _vm.state.activeTabComponent.rights ? _c('Assignments', {
    attrs: {
      "component-table-headers": _vm.constants.ASSIGNMENTS_TABLE_HEADERS,
      "additional-filters": _vm.constants.ADDITIONAL_ASSIGNMENT_FILTERS_LOWER,
      "without-filter-persistence": true,
      "without-person-dropdown": true,
      "elevation": 0,
      "assignments-need-reload": _vm.needReload,
      "is-common-view": false
    },
    on: {
      "reload-profile-data": function reloadProfileData($event) {
        return _vm.listeners.onReloadUpperData();
      },
      "reloaded-assignments": function reloadedAssignments($event) {
        return _vm.listeners.onReloadedLowerData();
      }
    }
  }) : _vm._e(), _c(_vm.state.activeTabComponent.component, {
    tag: "component",
    attrs: {
      "person": _vm.state.person,
      "boarding-type": _vm.state.activeTabComponent.boardingType,
      "without-filter-persistence": true
    },
    on: {
      "reload-person": function reloadPerson($event) {
        return _vm.listeners.onReloadUpperData(_vm.constants.RELOAD_TYPE.PERSON);
      },
      "reload-data": function reloadData($event) {
        return _vm.listeners.onReloadUpperData();
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }