
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'

import UpperPersonProfile from '@/views/persons/views/personProfile/components/upperPersonProfile/UpperPersonProfile.vue'
import LowerPersonProfile from '@/views/persons/views/personProfile/components/lowerPersonProfile/LowerPersonProfile.vue'

import { RELOAD_TYPE } from './types'

export default defineComponent({
  name: 'PersonProfile',
  components: {
    UpperPersonProfile,
    LowerPersonProfile,
  },
  setup: (_, { root }) => {
    const currentPersonId = computed(() => Number(root.$route.params.id))

    const reloadAssignments = ref(false)
    const reloadPerson = ref(false)

    function onReloadedData(): void {
      reloadPerson.value = false
      reloadAssignments.value = false
    }

    function handleDataReload(reloadType: RELOAD_TYPE) {
      if (reloadType === RELOAD_TYPE.PERSON) {
        reloadPerson.value = true
      } else if (reloadType === RELOAD_TYPE.ASSIGNMENTS) {
        reloadAssignments.value = true
      } else {
        reloadPerson.value = true
      }
    }

    return reactive({
      constants: {
        RELOAD_TYPE,
      },
      state: {
        currentPersonId,

        reloadAssignments,
        reloadPerson,
      },

      listeners: {
        handleDataReload,
        onReloadedData,
      },
    })
  },
})
